











































import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Ref } from 'vue-property-decorator'
import DraggableTreeview from 'vuetify-draggable-treeview'

// components
import Confirmation from '@/components/modals/Confirmation.vue'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import MasterMobileFoldersModal from '@/components/modals/depositories/master/MasterMobileFoldersModal.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import ManagerDepositoryModule from '@/store/modules/manager/depository'
import MasterDepositoryModule from '@/store/modules/master/depository'

@Component({
  components: {
    ButtonTextIcon,
    Confirmation,
    DraggableTreeview,
    MasterMobileFoldersModal,
  },
})
export default class DepositoryFilesMonth extends Mixins(NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  private get monthID () {
    return +this.$route.params.monthID
  }

  private get courseID () {
    return +this.$route.params.courseID
  }

  private get course () {
    return MasterDepositoryModule.courses.data.find(course => course.id === this.courseID)
  }

  private get title() {
    return MasterDepositoryModule.trainingFilesMonth.folders.find(folder => folder.id === this.monthID)?.title
  }

  private showMobileManualModal = false

  private get folders() {
    return MasterDepositoryModule.trainingFilesTree
  }

  private get isTreePage () {
    return this.$route.name === 'master.depository.item.files.month'
  }

  private get currentManual() {
    return ManagerDepositoryModule.manual
  }

  private get breadcrumbs () {
    if (!this.course) {
      return [{ name: 'База знаний', path: { name: 'master.depository' } }]
    } else {
      return [
        { name: 'База знаний', path: { name: 'master.depository' } },
        { name: this.course.title, path: { name: 'master.depository.item', params: { courseID: this.courseID.toString() } } },
        { name: 'Учебные файлы', path: { name: 'master.depository.item.files', params: { courseID: this.courseID.toString() } } },
      ]
    }
  }

  private mounted () {
    if (!this.course || this.course.id !== this.courseID) {
      MasterDepositoryModule.fetchCourses()
        .catch(this.notifyError)
    }
    MasterDepositoryModule.fetchTrainingFilesTree({ courseID: this.courseID, courseFolderID: this.monthID })
      .catch(this.notifyError)

    if (!MasterDepositoryModule.trainingFilesMonth.courseID || this.courseID !== MasterDepositoryModule.trainingFilesMonth.courseID) {
      MasterDepositoryModule.fetchTrainingFilesMonth({ courseID: this.courseID })
        .catch(err => {
          if (err && err.response && err.response.status === 403) {
            this.$router.replace({ name: 'master.depository' })
              .catch(() => {return})
          }
          this.notifyError(err)
        })
    }
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Учебные файлы',
    }
  }
}
